import React, { useEffect, useRef, useContext, useState } from 'react';
import styles from './FullscreenWelcomeSection.module.scss';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LogoReel from 'Components/LogoReel/LogoReel';
import { toValidLocaleString } from 'Utils/toValidLocaleString';
import {
  LOGO_REEL,
  POPULAR_SEARCH_TERMS,
  HOME_MAIN_ARROW_DOWN,
} from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import loadable from '@loadable/component';
import useMediaQuery from 'Utils/useMediaQuery';
import { SSRContext } from 'Utils/SSRContext';
import { SwitchableFeature } from 'Features/SwitchableFeature/SwitchableFeature';
import BackgroundImage from 'Components/BackgroundImage';
import { ReactComponent as ArrowDown } from '../../images/icons/arrowDown.svg';

const Searchbar = loadable(() => import('Components/Searchbar/Searchbar'));
const Header = loadable(() => import('Components/Header/Header'));
const StickyHeader = loadable(() => import('Components/StickyHeader/StickyHeader'));
const MIN_NUMBER_OF_SHOPS_TO_DISPLAY_SHOP_STATS = 100;

const FullscreenWelcomeSection = ({ stats, clickoutSlider }) => {
  const { config, clickout } = useSelector(({ config, clickout }) => ({ config, clickout }));
  const { isDesktopSSR } = useContext(SSRContext);
  const [showStickyHeader, setShowStickyHeader] = useState(false);

  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery(isDesktopSSR);

  const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);
  /**
   * Show/hide sticky header
   */
  useEffect(() => {
    console.log('UE isDesktopSSR');
    setShowStickyHeader(!isDesktopSSR);
  }, [isDesktopSSR]);

  const intersection = useRef(null);
  useEffect(() => {
    if (isDesktop && intersection.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setShowStickyHeader(!entry.isIntersecting);
          });
        },
        { root: null, threshold: 1, rootMargin: '-15px' }
      );
      const intersectionNode = intersection.current.base || intersection.current;
      observer.observe(intersectionNode);

      return () => {
        observer.unobserve(intersectionNode);
      };
    }
  }, []);

  return (
    <main
      className={classnames(styles.main, {
        [styles.mainFullHeight]: config?.home?.enableFullHeightMain,
      })}
      data-testid="HomepageMain"
      id="readyForCrawler"
    >
      {/* This the initial viewport */}

      {/* background image */}
      <BackgroundImage src={config.theme.images.homeIllustration} />

      {/* Regular and sticky header */}
      <Header />
      {showStickyHeader && (
        <div
          className={classnames(styles.stickyHeaderWrapper, { [styles.stickyHeaderWrapper_shown]: showStickyHeader })}
        >
          <StickyHeader />
        </div>
      )}
      <div className={classnames(styles.mainContent, 'container')}>
        {/* Slogan above the search bar */}
        <h1 className={classnames(styles.slogan)} data-testid="HomePageSlogan">
          <span className={classnames(styles.sloganBoldPart)}>{config.home?.slogan?.[0] || 'Whatever it be,'}</span>
          <span>{config.home?.slogan?.[1] || "you'll find it at Webshop!"}</span>
        </h1>

        {/* search bar */}
        <div className={classnames(styles.searchBarContainer)}>
          {isDesktop && <Searchbar ref={intersection} autoFocus={false} isDesktop={isDesktop} SAYT={false} />}

          {/* popular search terms below search bar */}
          <SwitchableFeature feature={POPULAR_SEARCH_TERMS}>
            <div className={classnames(styles.popularSearchTermsContainer, 'row')}>
              <ul className={classnames(styles.popularSearchTerms, 'col-12-xs')}>
                <li>
                  <FormattedMessage id="home.popularSearchTerms" defaultMessage="Popular search terms" />:
                </li>
                <FormattedMessage id="search.popularTerms" defaultMessage="Lego, Ultra HD TV, iPhoneX">
                  {(terms) =>
                    typeof terms === 'string' &&
                    terms.split(',').map((term, idx, arr) => (
                      <li className={styles.popularSearchTerm} key={`popularSearchTerm_${idx}`}>
                        <Link to={`/${config.cmsSlugs.search}/?q=${term.trim()}`}>
                          {term.trim()}
                          {idx !== arr.length - 1 ? ',' : ''}
                        </Link>
                      </li>
                    ))
                  }
                </FormattedMessage>
              </ul>
            </div>
          </SwitchableFeature>
          {/* A bunch of logos below the search bar */}
          <SwitchableFeature feature={LOGO_REEL}>
            <div className={styles.logoReelContainer}>
              <LogoReel clickoutSlider={clickoutSlider} loading={false} error={!clickoutSlider} clickout={clickout} />
            </div>
          </SwitchableFeature>
          <div className={styles.statsContainer}>
            {!!stats?.total && (
              <div className={styles.stats}>
                <span className={styles.statsValue}>{toValidLocaleString(stats?.total)}</span>
                <FormattedMessage id="home.productsStats" defaultMessage="Products" />
              </div>
            )}
            {stats?.shops >= MIN_NUMBER_OF_SHOPS_TO_DISPLAY_SHOP_STATS && (
              <div className={styles.stats}>
                <span className={styles.statsValue}>{toValidLocaleString(stats?.shops)}</span>
                <FormattedMessage id="home.shopsStats" defaultMessage="Shops" />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Arrow down at the screen bottom */}
      <SwitchableFeature feature={HOME_MAIN_ARROW_DOWN}>
        <div className={styles.mainArrowDown}>
          <ArrowDown />
        </div>
      </SwitchableFeature>
    </main>
  );
};

export default FullscreenWelcomeSection;
